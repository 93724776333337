import './App.css';
import Home from './Home'; // Import the Home component

function App() {
  return (
    <div className="App">
      <header>
        <Home /> {/* Use the Home component */}
      </header>
    </div>
  );
}

export default App;
